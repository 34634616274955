
import { mapActions } from 'vuex'
import QuantityCartSelector from '@/components/QuantityCartSelector'
import RatioImage from '@/components/RatioImage'

export default {
  components: {
    RatioImage,
    QuantityCartSelector,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
    quantity: {
      type: [String, Number],
      default: null,
    },
    variantTitle: {
      type: String,
      default: null,
    },
    undiscounted_price: {
      type: [String, Number],
      default: null,
    },
    price: {
      type: [String, Number],
      default: null,
    },
    index: {
      type: Number,
      default: 0,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    isAddToBag: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      hasMounted: false,
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.hasMounted = true
    })
  },
  methods: {
    ...mapActions('ui', ['doHideSider']),
    updateQuantity() {
      if (!this.hasMounted) return
      this.$emit('updateQuantity', this.product, this.quantity)
    },
    remove() {
      if (!this.hasMounted) return
      this.$emit('remove', this.product)
    },
  },
}
