
import FooterMenu from '@/containers/footer/FooterMenu'

export default {
  components: {
    FooterMenu,
  },
  data() {
    return {
      subscribeEmail: '',
      subscribeError: null,
      isJustSubscribed: false,
      isMobileMenuOpen: false,
    }
  },
  methods: {
    getCurrency(str) {
      const country = this.$i18n.locales.filter((locale) => {
        return locale.code === this.$i18n.locale
      })

      return new Intl.NumberFormat(this.$i18n.locale, {
        style: 'currency',
        currency: country[0].currencyCode,
        maximumSignificantDigits: 3,
      }).format(str)
    },
    scrollToTop() {
      this.$emit('scrollToTop')
    },
    async subscribe() {
      if (
        !this.subscribeEmail.includes('@') ||
        !this.subscribeEmail.includes('.')
      ) {
        this.subscribeError = this.$t('enter_valid_email')
        return
      }

      try {
        const result = await this.$axios.$post('newsletters/email', {
          email: this.subscribeEmail,
        })

        if (result) {
          this.subscribeError = null
          this.isJustSubscribed = true
          this.subscribeEmail = ''

          setTimeout(() => {
            this.isJustSubscribed = false
          }, 2000)
        }
      } catch (e) {
        this.subscribeError = 'Cannot subscribe, please try again later'
      }
    },
  },
}
