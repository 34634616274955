
import { mapActions, mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters('cookies', ['isCookiesBarActive', 'isCookiesAccepted']),
  },
  mounted() {
    this.$root.$on('bodyscroll', this.watchScroll)
  },
  destroyed() {
    this.$root.$off('bodyscroll', this.watchScroll)
  },
  methods: {
    ...mapActions('cookies', [
      'acceptCookies',
      'showCookiesBar',
      'hideCookiesBar',
    ]),
    watchScroll(e) {
      if (this.isCookiesAccepted) {
        this.hideCookiesBar()
        this.$root.$off('bodyscroll', this.watchScroll)
        return
      }

      const scrollTop = window.pageYOffset

      if (scrollTop > 35) {
        this.hideCookiesBar()
      } else {
        this.showCookiesBar()
      }
    },
  },
}
