import { render, staticRenderFns } from "./default.vue?vue&type=template&id=19e4fff2&scoped=true"
import script from "./default.vue?vue&type=script&lang=js"
export * from "./default.vue?vue&type=script&lang=js"
import style0 from "./default.vue?vue&type=style&index=0&id=19e4fff2&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "19e4fff2",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Cookies: require('/opt/atlassian/pipelines/agent/build/containers/Cookies.vue').default,Header: require('/opt/atlassian/pipelines/agent/build/containers/header/Header.vue').default,Footer: require('/opt/atlassian/pipelines/agent/build/containers/footer/Footer.vue').default,Wishlist: require('/opt/atlassian/pipelines/agent/build/containers/Wishlist.vue').default,Sider: require('/opt/atlassian/pipelines/agent/build/components/Sider.vue').default,Account: require('/opt/atlassian/pipelines/agent/build/containers/Account.vue').default,Login: require('/opt/atlassian/pipelines/agent/build/containers/Login.vue').default,AddToBagFeedback: require('/opt/atlassian/pipelines/agent/build/containers/AddToBagFeedback.vue').default,Notifications: require('/opt/atlassian/pipelines/agent/build/containers/Notifications.vue').default,Icon: require('/opt/atlassian/pipelines/agent/build/components/Icon.vue').default})
