
import SiderHeader from '@/components/SiderHeader'
import LoginForm from '@/containers/account/forms/LoginForm'
import SignupForm from '@/containers/account/forms/SignupForm'
import SocialLogin from '@/containers/account/SocialLogin'

export default {
  components: { SiderHeader, LoginForm, SignupForm, SocialLogin },

  data() {
    return {
      currentTab: 'signin',
    }
  },

  methods: {
    closeAll() {
      this.$store.dispatch('ui/closeAll')
    },

    changeTab(tab) {
      this.currentTab = tab
    },
  },
}
