
import Icon from '@/components/Icon'

export default {
  components: {
    Icon,
  },
  data() {
    return {
      accordions: {
        categories: false,
        help: false,
        about: false,
      },
    }
  },
}
