var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"app-layout flex flex-col w-full",class:{ 'app-layout--dark': _vm.shouldUseDarkBackground }},[(_vm.isCookiesBarActive)?_c('Cookies'):_vm._e(),_vm._v(" "),_c('div',{ref:"appBody",staticClass:"app-body flex flex-col flex-grow overflow-y-hidden overflow-x-hidden min-h-screen w-full",class:[
      { 'is-cookies-active': _vm.isCookiesBarActive },
      { 'is-homepage': _vm.isHomepage },
    ]},[_c('Header',{attrs:{"trigger-hide-sidebar":_vm.hideSidebar}}),_vm._v(" "),_c('div',{staticClass:"app-page flex-grow"},[_c('nuxt')],1),_vm._v(" "),(_vm.isFooterActive)?_c('Footer',{on:{"scrollToTop":_vm.scrollToTop}}):_vm._e()],1),_vm._v(" "),_c('Sider',{staticClass:"lg:border-l",attrs:{"active":_vm.isWishlistActive,"name":"wishlist"},on:{"hideSidebar":_vm.onHideSidebar}},[(_vm.isWishlistActive)?_c('Wishlist'):_vm._e()],1),_vm._v(" "),_c('Sider',{staticClass:"lg:border-l",attrs:{"active":_vm.isAccountActive,"name":"account"},on:{"hideSidebar":_vm.onHideSidebar}},[(_vm.$auth.loggedIn)?[(_vm.isAccountActive)?_c('Account'):_vm._e()]:[(_vm.isAccountActive)?_c('Login'):_vm._e()]],2),_vm._v(" "),(_vm.isAddToBagFeedbackActive)?_c('AddToBagFeedback',{staticClass:"z-50 lg:hidden"}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"fixed top-0 left-0 w-full h-full z-10",class:{
      'opacity-0 invisible': !_vm.shouldBackdropBeVisible,
      'opacity-100 visible': _vm.shouldBackdropBeVisible,
    },style:(_vm.shadow || _vm.isSidebarActive ? 'background-color: rgba(0,0,0,0.4)' : ''),on:{"click":_vm.closeAll}}),_vm._v(" "),(_vm.list.length > 0)?_c('Notifications'):_vm._e(),_vm._v(" "),_c('script',{attrs:{"type":"application/ld+json"},domProps:{"innerHTML":_vm._s(JSON.stringify(_vm.OrganizationSchema))}}),_vm._v(" "),_c('div',{ref:"judoframe",staticClass:"hidden",attrs:{"id":"judoframe"}}),_vm._v(" "),(_vm.expressLoaderModal)?_c('div',{staticClass:"loader-modal"},[_c('p',{staticClass:"flex items-center"},[_c('Icon',{staticClass:"text-lg lg:text-xl mx-2",attrs:{"name":"spinner","set":"fal","spin":""}}),_vm._v("\n      Loading\n    ")],1)]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }