
import SiderHeader from '@/components/SiderHeader'
import { removeLocalStorage } from '~/plugins/syncLocalStorage'
export default {
  components: { SiderHeader },
  methods: {
    logout() {
      this.$auth.logout()

      removeLocalStorage('reference_customer')
      // clear checkout
      this.$store.dispatch('checkout/clear')
      this.$store.dispatch('session/removeSession')
      this.$store.dispatch('cart/emptyCartAndOrder')
    },
  },
}
