
import { mapActions } from 'vuex'
import SiderProduct from '@/components/SiderProduct'
import SiderHeader from '@/components/SiderHeader'

export default {
  components: {
    SiderProduct,
    SiderHeader,
  },
  methods: {
    ...mapActions('wishlist', ['removeFromWishlist']),
    ...mapActions('cart', ['addToCart']),
    ...mapActions('ui', ['doShowSider']),
    sendToCart(product) {
      this.addToCart(product).then(() => {
        // this.removeFromWishlist(product)

        this.doShowSider('cart')
      })
    },
  },
}
