
import { mapActions, mapGetters } from 'vuex'
import Notification from '@/components/Notification'

export default {
  components: {
    Notification,
  },
  computed: {
    ...mapGetters('notification', ['list', 'reversed']),
  },
  methods: {
    ...mapActions('notification', ['dismissNotification']),
  },
}
