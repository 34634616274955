
import { mapActions, mapState } from 'vuex'

export default {
  name: 'QuantityCartSelector',
  props: {
    product: { type: Object, required: true },
    quantity: { type: Number, required: true },
    free: { type: Number, default: 0 },
  },
  data() {
    return {}
  },
  computed: {
    ...mapState('cart', ['isLoading']),
  },
  methods: {
    ...mapActions('cart', ['updateQuantity', 'remove']),
    removeProduct(product) {
      if (this.isLoading) {
        return
      }

      this.remove(product)
    },
    selectQuantity(qtyValue, product) {
      if (this.isLoading) {
        return
      }

      const carts = [...this.$store.state.cart.items]
      const currProduct = carts.find(
        (item) => item.product_variant_id === product.product_variant_id
      )
      const newQuantity =
        currProduct.quantity - currProduct.free_product_count + qtyValue

      if (product.isFreeCart !== true) {
        if (qtyValue > 0) {
          this.updateQuantity({ cart: currProduct, quantity: qtyValue })
        } else {
          this.removeProduct(currProduct.product)
        }
      } else {
        this.updateQuantity({ cart: currProduct, quantity: newQuantity })
      }
    },
  },
}
