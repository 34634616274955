
import Icon from '@/components/Icon'

export const typeVariations = {
  default: {
    base: 'bg-white border-gray-300',
    close: 'bg-transparent text-gray-700',
  },
  primary: {
    base: 'bg-blue-500 text-white hover:bg-blue-600 border-blue-600',
    close: 'bg-transparent text-white',
  },
  success: {
    base: 'bg-green-500 text-green-100 hover:bg-green-600 border-green-600',
    close: 'bg-transparent text-white',
  },
  error: {
    base: 'bg-red-500 text-white hover:bg-red-600 border-red-600',
    close: 'bg-transparent text-white',
  },
}

export default {
  components: {
    Icon,
  },
  props: {
    notification: {
      type: Object,
      default: () => null,
    },
    type: {
      type: String,
      default: 'default',
    },
  },
  data() {
    return {
      shown: false,
      closing: false,
    }
  },
  computed: {
    typeClasses() {
      return typeVariations[this.type] || typeVariations.default
    },
  },
  mounted() {
    this.$nextTick(() => {
      setTimeout(() => {
        this.shown = true
      }, 10)

      if (this.notification.dismissable && this.notification.duration) {
        setTimeout(this.dismiss, this.notification.duration)
      }
    })
  },
  methods: {
    dismiss() {
      this.closing = true
      this.shown = false

      setTimeout(() => {
        this.$emit('dismiss')
        this.closing = false
      }, 500)
    },
  },
}
