
import { mapActions } from 'vuex'
export default {
  props: {
    title: {
      type: String,
      default: null,
    },
  },
  methods: {
    ...mapActions('ui', ['toggleSider']),
  },
}
