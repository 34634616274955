
import { mapGetters } from 'vuex'
// import CheckoutBar from '@/components/CheckoutBar'
import SiderProduct from '@/components/SiderProduct'

export default {
  components: {
    // CheckoutBar,
    SiderProduct,
  },
  computed: {
    ...mapGetters('cart', ['total', 'totalPrice', 'lastAddedItem']),
    ...mapGetters('ui', ['isAddToBagFeedbackActive']),
    product() {
      if (this.lastAddedItem)
        return Object.assign(
          {},
          this.lastAddedItem.product,
          this.lastAddedItem.productVariant
        )

      return null
    },
  },
  methods: {
    close() {
      this.$store.dispatch('ui/setAddToBagFeedback', false)
    },
  },
}
