import { render, staticRenderFns } from "./AddToBagFeedback.vue?vue&type=template&id=7ac6d22a&scoped=true"
import script from "./AddToBagFeedback.vue?vue&type=script&lang=js"
export * from "./AddToBagFeedback.vue?vue&type=script&lang=js"
import style0 from "./AddToBagFeedback.vue?vue&type=style&index=0&id=7ac6d22a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7ac6d22a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Icon: require('/opt/atlassian/pipelines/agent/build/components/Icon.vue').default,Button: require('/opt/atlassian/pipelines/agent/build/components/Button.vue').default,SiderProduct: require('/opt/atlassian/pipelines/agent/build/components/SiderProduct.vue').default,Button: require('/opt/atlassian/pipelines/agent/build/components/Button.vue').default})
